<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady" @Hide="onhide">
      <div style="position:relative">
        <h5 v-if="config.title">
          {{config.title}}
        </h5>
        <div class="" style="position:absolute;top:0;right:0;padding:5px;cursor:pointer;" @click="$refs.model.hideModal()">
          <i class="fa fa-times"></i>
        </div>
        <div v-html="config.html">
        </div>
      </div>
    </component>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      intro() {
        return this.publicData.intro || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      onhide() {
        this.$emit('next')
      }
    },
  }
</script>
<style scoped>
  .introText {
    color: white;
    text-align: justify;
  }
</style>
